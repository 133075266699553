.kpi {
	display: flex;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	margin-bottom: 3rem;
	@include center();
	&-box {
		box-sizing: border-box;
		position: relative;
		width: 100%;
		margin: 0 1rem;
		padding: 2rem;
		background-color: white;
		color: $blue;
		border-radius: 2rem;
		@include shadow();
		@include transition-short();
		&:first-of-type {
			margin-left: 0;
		}
		&:last-of-type {
			margin-right: 0;
		}
		&-heading {
			font-size: 1.4rem;
			margin: 0;
			margin-bottom: 1.4rem;
		}
		&-values {
			display: flex;
			align-items: center;
			justify-content: space-between;
			&-value {
				font-size: 3rem;
				margin: 0;
				@include transition-short();
			}
			&-difference {
				display: flex;
				align-items: center;
				&-value {
					position: relative;
					top: -0.1rem;
					font-size: 1.6rem;
					margin-right: 1rem;
					@include transition-short();
				}
				svg {
					font-size: 1.4rem;
				}
			}
		}

		@media (prefers-color-scheme: dark) {
		}
	}
}

@media screen and (max-width: 1400px) {
	.kpi {
		flex-wrap: wrap;
		&-box {
			width: 49%;
			margin: 0;
			margin-bottom: 1rem;
			&:last-of-type:not(:nth-of-type(even)) {
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

@media screen and (max-width: 992px) {
	.kpi {
		&-box {
			&-values {
				flex-direction: column;
				align-items: flex-start;
				&-difference {
					display: flex;
					svg {
						order: 1;
						margin-right: 1rem;
					}
					&-value {
						order: 2;
						margin: 0;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.kpi {
		margin-bottom: 2rem;
		&-box {
			width: 100%;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
