.category {
	scroll-margin-top: 5rem;
	&-header {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 1rem 0;
		@include transition-short();
		p {
			width: 100%;
			margin-bottom: 0;
		}
		&--is-edited {
			margin-top: 1rem;
			margin-bottom: 1rem !important;
			padding: 0;
			width: 60%;
			.table {
				&-header {
					th {
						&.has_images,
						&.save {
							text-align: center;
						}
					}
				}
				&-body {
					.name {
						input {
							width: 10rem;
							font-size: 1.1rem;
						}
					}
					.description {
						input {
							width: 100%;
						}
					}
					input[type="checkbox"],
					.button {
						display: block;
						margin-left: auto;
						margin-right: auto;
					}
				}
			}
		}

		&-heading {
			display: block;
			margin: 0 2rem 0 0;
			@include transition-short();
		}
		.button {
			border: 1px solid transparent;
			@include transition-short();
		}
		&-button {
			&--save-category {
				height: 1.6rem !important;
				width: 1.6rem !important;
				padding: 0;
				border-radius: 1.6rem;
				border-color: $green !important;
				svg {
					margin: 0;
				}
				&:hover {
					background-color: $green;
					svg {
						color: white;
					}
				}
			}
			&--move-category {
				display: block;
				height: auto !important;
				width: max-content !important;
				padding: 0%;
				background-color: transparent;
				box-shadow: none !important;
				&:hover {
					background-color: transparent;
				}
				svg {
					color: $blue !important;
					margin-right: 0;
				}
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.category {
		&-header {
			justify-content: space-between;
			&-heading {
				width: calc(100% - 3.6rem);
				display: inline-block !important;
				margin: 0;
				margin-bottom: 0.4rem;
			}
			.button {
				margin: 0;
				margin-bottom: 0.4rem;
				width: 49%;
				order: 3;
				&--delete {
					order: 6;
					margin-left: auto;
					margin-right: auto;
				}
			}
			&-button {
				&--move-category {
					height: 1.6rem !important;
					width: 1.6rem !important;
					border: 1px solid $blue-dark !important;
					order: 2 !important;
					margin-left: 0.2rem !important;
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.category {
		&-header {
			.button {
				width: 100%;
			}
		}
	}
}
