.table {
	width: 100%;
	height: 100%;
	border-spacing: 0;
	@include center();
	border-collapse: separate;
	overflow: auto;
	@include transition-short();
	&-container {
		border-radius: 1.6rem;
		overflow: hidden;
		@include shadow();
		margin-bottom: 3rem;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
	&-header {
		color: white;
		tr {
			@include gradient($orange, $pink);
			min-height: 0.4rem;
			th {
				text-align: left;
				padding: 0.3rem 0.6rem 0.5rem;
				svg {
					margin-right: 0.4rem;
				}
			}
		}
	}
	&-body {
		border-radius: 1.6rem;
		@include transition-short();
		&-row {
			&--is-edited {
				td {
					background-color: $gray-light;
				}
			}
		}
		tr {
			background-color: white;
			@include transition-short();
			td {
				padding: 0.6rem 0.8rem;
				vertical-align: middle;
				border-top: 2px solid $gray-light;
				@include transition-short();
			}
			&:first-child {
				td {
					border-top: none;
				}
			}
		}
	}

	&.restauranttable {
		tbody {
			tr {
				td {
					&.payments {
						span {
							margin-right: 0.4rem;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1400px) {
	.ordertable {
		tr {
			th {
				svg {
					display: none;
					margin: 0 auto 0.4rem;
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {
	.table {
		display: block;
		max-width: -moz-fit-content;
		max-width: fit-content;
		overflow-x: auto;
		white-space: nowrap;
		width: 100%;
	}
	.productstable {
		width: 100%;
		overflow-x: auto;
		&-container {
			width: max-content;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

@media screen and (max-width: 576px) {
	.table {
		&-container {
			margin-bottom: 2rem;
		}
	}
}
