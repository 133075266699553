.page-orders {
	.table {
		&-container {
			margin-bottom: 0;
		}
	}
	&-buttons {
		display: flex;
		justify-content: center;
		margin-bottom: 2rem;
		&-button {
			&-next {
				svg {
					margin-right: 0;
					margin-left: 0.4rem;
				}
			}
		}
	}
}
