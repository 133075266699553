$yellow: #f1bf15;
$pink: #f8507d;
$pink-light: #ffced2;
$pink-dark: #d04369;
$orange: #f5864a;
$orange-dark: #ce713e;
$green: #2ecc71;
$red: #dc4346;
$gray-light: #ecf0f1;
$gray-semilight: #d6d6d6;
$blue-light: #64819e;
$blue: #34495e;
$blue-dark: #23313f;
$blue-extradark: #151d26;

:export {
	YELLOW: $yellow;
	PINK: $pink;
	GREEN: $green;
}
