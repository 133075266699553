@mixin buttonStyle($color) {
	border: 1px solid transparent;
	border-color: $color !important;
	&:hover {
		background-color: $color;
	}
}

.button {
	display: block;
	font-family: "Poppins", sans-serif;
	padding: 0.4rem 1rem;
	color: $blue;
	background-color: white;
	text-decoration: none;
	border: none;
	margin-right: 0.4rem;
	border-radius: 2rem;
	width: max-content;
	transition: 0.1s all linear;
	@include shadow();
	@include transition-short();
	svg {
		margin-right: 0.4rem;
	}
	&:hover {
		background-color: $orange;
		color: white;
		cursor: pointer;
	}

	&--change-theme {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		z-index: 10;
		height: 3rem;
		width: 3rem;
		right: 1rem;
		bottom: 1rem;
		margin-right: 0;
		font-size: 1.2rem;
		svg {
			margin-right: 0;
		}
		&:hover {
			background-color: $blue;
			color: white;
		}
	}

	&--add {
		@include buttonStyle($green);
	}
	&--edit {
		@include buttonStyle($yellow);
	}
	&--delete {
		@include buttonStyle($red);
	}
}
