.menu {
	position: fixed;
	left: 0;
	display: flex;
	z-index: 1;
	background-color: white;
	width: 100vw;
	justify-content: center;
	@include shadow();
	&-nav {
		position: relative;
		display: flex;
		align-items: center;
		&-name {
			margin-right: 4rem;
		}
		&-link {
			position: relative;
			top: 0.2rem;
			font-size: 1.2rem;
			text-align: center;
			text-decoration: none;
			color: $blue;
			margin-right: 2rem;
			@include transition-short();
			@include underline();
			&--is-active {
				&::after {
					transform: scaleX(1) !important;
					border-bottom: 1px solid $blue !important;
				}
			}
			&:last-of-type {
				margin-right: 0;
			}
			&::after {
				margin-top: 0.4rem;
			}
		}
		&-logout-button {
			display: flex;
			justify-content: center;
			align-items: center;
			position: absolute;
			right: -0.5ch;
			margin-right: 0;
			height: 2rem !important;
			width: 2rem !important;
			padding: 0;
			color: $red;
			@include transition-short();
			border-radius: 2rem;
			svg {
				margin-right: 0;
			}
		}
		&--mobile {
			display: none;
		}
	}
}

@media screen and (max-width: 1200px) {
	.menu {
		&-nav {
			display: none;
			&--mobile {
				display: flex;
				justify-content: space-between;
				align-items: center;
				&-openburger {
					display: flex;
					width: 2rem;
					height: 2rem;
					padding: 10px;
					background-color: transparent;
					flex-direction: column;
					margin-right: 0;
					&:hover {
						span {
							background-color: white;
						}
					}

					span {
						display: block;
						width: 100%;
						height: 2px;
						background-color: $red;
						border-radius: 20px;

						&:nth-child(1) {
							margin-bottom: 2px;
						}

						&:nth-child(3) {
							margin-top: 2px;
						}
					}
				}

				&-closeburger {
					align-self: flex-end;
					display: flex;
					justify-content: center;
					align-items: center;
					height: 2rem;
					width: 2rem;
					padding: 0;
					margin-top: 1rem;
					margin-right: 1rem;
					background-color: transparent;
					align-self: flex-end;
					svg {
						margin-right: 0;
					}

					&:hover {
						background-color: $red;
						cursor: pointer;
						color: white;
						svg {
							color: white;
						}
					}
				}
				&-sidebar {
					display: flex;
					flex-direction: column;
					height: 100vh;
					min-width: 30vw;
					background-color: white;
					z-index: 20;
					position: fixed;
					top: 0;
					right: 0;
					transform: translateX(100%);
					@include transition-short();

					&.is-open {
						transform: translateX(0%);
						@include shadow();
					}

					&.is-closed {
						transform: translateX(100%);
					}

					& > .menu-nav-link {
						margin: 1rem 1rem 0;
					}

					& > .menu-nav-logout-button {
						display: flex;
						justify-content: center;
						align-items: center;
						position: static;
						margin: 2rem auto 0;
						padding: 0;
						svg {
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width: 992px) {
}

@media screen and (max-width: 768px) {
	.menu {
		&-nav {
			&--mobile {
				&-sidebar {
					min-width: 50vw;
				}
			}
		}
	}
}

@media screen and (max-width: 576px) {
	.menu {
		&-nav {
			&-name {
				font-size: 1.2rem;
				margin-right: 1rem;
			}
			&--mobile {
				&-sidebar {
					min-width: 70vw;
				}
			}
		}
	}
}
