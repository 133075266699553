@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loader {
    display: block;
    width: 5rem;
    height: 5rem;
    border: 1rem solid $orange;
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-radius: 5rem;
    margin-left: auto;
    margin-right: auto;
    animation: rotate 0.5s linear 0s infinite;
}