.fa-arrow-up {
	color: $green;
}
.fa-arrow-down {
	color: $red;
}
.fa-minus {
	color: $yellow;
}
.fa-check {
	color: $green;
}
.fa-times {
	color: $red;
}
