.page {
	display: flex;
	flex-direction: column;
	padding-top: 8rem;
	margin: 0 auto;
	& > h1 {
		margin-top: 0;
	}
}

@media screen and (max-width: 576px) {
	.page {
		padding-top: 5rem;
	}
}
