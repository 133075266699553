.layout {
	display: flex;
	flex-direction: column;
	align-items: center;
}

footer {
	display: flex;
	justify-content: center;
	padding: 3rem 0;
	text-decoration: none;
	a {
		display: inline-block;
		margin: 0 0.4ch;
		color: $blue;
		text-decoration: none;
		@include underline();
	}
}
