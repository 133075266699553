.charts {
	display: flex;
	justify-content: space-between;
	width: 100%;
	@include center();
	&-chartcontainer {
		background-color: white;
		padding: 1.4rem;
		border-radius: 1.6rem;
		position: relative;
		width: 100%;
		margin: 0px 1rem;
		height: auto !important;
		box-sizing: border-box;
		@include shadow();
		&:first-of-type {
			margin-left: 0;
		}
		&:last-of-type {
			margin-right: 0;
		}
		canvas {
			width: 100% !important;
			height: 100% !important;
		}
	}
}

@media screen and (max-width: 992px) {
	.charts {
		flex-direction: column;
		align-items: center;
		&-chartcontainer {
			width: 100%;
			margin: 0;
			&:first-of-type {
				margin-bottom: 1rem;
			}
		}
	}
}
