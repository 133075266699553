.page-items {
	&-links {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		.button {
			border: 1px solid transparent;
			font-size: 0.85rem;
			margin-right: 0.4rem;
			margin-top: 0.2rem;
		}
	}
	&-button {
		&--add-category {
			margin-top: 0.2rem;
			border: 1px solid transparent;
			border-color: $green !important;
			&:hover {
				background-color: $green;
			}
		}
	}
}
