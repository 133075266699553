@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&family=Raleway:wght@300;400;500;600&family=Work+Sans:wght@100;200;300;400;500;600&display=swap");

:root {
	color: $blue !important;
}

html,
body {
	margin: 0 !important;
	padding: 0 !important;
	background-color: $gray-light;
	overflow-x: hidden;
	scroll-behavior: smooth;
	font-family: "Poppins", sans-serif !important;
}

body {
	width: 100vw;
}

.App {
	min-height: 100vh;
}

.container {
	width: 90%;
}

h2 {
	font-size: 1.6rem;
}

#chat-application {
	left: 1rem !important;
	bottom: 1rem !important;
}

@media screen and (max-width: 768px) {
	.container {
		width: 85%;
	}
}

@media screen and (max-width: 576px) {
	.container {
		max-width: 100%;
	}
}
